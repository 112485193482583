import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { LoadedData } from '../../../toolympus/hooks/useLoadedData';
import { PMTask, TaskSchema } from './types';
import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { DictionarySelectMultiDisplay } from '../../../toolympus/components/schemed/Select';
import { TimeDisplay } from '../../../toolympus/components/schemed/TimeDisplay';

const Title = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
  color: inherit;
`;

const Wrapper = styled.div`
  border-radius: 16px;
  background-color: #ffffff40;
  padding: 8px 16px;
`;


interface Props {
  data: LoadedData<PMTask[]>;
  title?: ReactNode;
  startEditing?: (t: PMTask) => void;
}

export const TaskListSmall = (props: Props) => {
  const { data, startEditing } = props;

  return (
    <Wrapper>
      <Title>{props.title}</Title>
      <TableContainer>
        <Table>
          <TableBody>
            {data.data.map(t => (
              <TableRow key={t._id} onClick={startEditing ? () => startEditing(t) : undefined}>
                <TableCell>
                  {t.title}
                </TableCell>
                <TableCell>
                  <TimeDisplay
                    field="due_date"
                    row={t}
                    schema={TaskSchema.due_date}
                    config={{ utcToLocal: true }}
                    />
                </TableCell>
                <TableCell>
                  <DictionarySelectMultiDisplay
                    field="assignees"
                    row={t}
                    schema={TaskSchema.assignees}
                    />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
}
