import React from "react";
import {
    Person,
    DescriptionOutlined,
    BookOutlined,
    People,
    ImportExport,
    Dashboard as DashboardIcon,
    PostAdd,
    Home,
    HelpOutline,
    Settings,
    ArchiveOutlined,
    RoomServiceOutlined,
    PollOutlined,
    TextFieldsOutlined,
    Numbers,
    TableViewOutlined,
    CalendarMonthOutlined,
} from "@mui/icons-material";

import CaseListContainer from "./components/Cases/CaseListContainer";
import CaseContainer from "./components/Cases/CaseContainer";
import ArbitratorListContainer from "./components/Arbitrators/ArbitratorListContainer";
import ArbitratorContainer from "./components/Arbitrators/ArbitratorContainer";
import { PracticeListContainer } from "./components/Practice/PracticeListContainer";
import ImportExportContainer from "./components/ImportExport/ImportExportContainer";
import { PracticeFormContainer } from "./components/Practice/PracticeFormContainer";
import { EditDashboardsContainer } from "./components/Dashboard/EditDashboardsContainer";
import { DashboardContainer } from "./components/Dashboard/DashboardContainer";
import { EmployeeList } from "./components/Employees/EmployeeList";
import { EmployeeForm } from "./components/Employees/EmployeeForm";
import { MenuRoute } from "./toolympus/components/frame";
import { isAdminManager, isCasefulUser } from "./components/UserManagement/roles";
import { PagePage } from "./components/Common/PagePage";
import { SystemSubmenu } from "./toolympus/components/Contests/SystemSubmenu";
import { PresidiumRoutes } from "./components/Presidium/Menu/PresidiumRoutes";
import { SnapshotsContainer } from "./toolympus/components/Snapshots/SnapshotsContainer";
import { CorpDisputesListPage } from "./components/CorpDisputes/CorpDisputesListPage";
import { CorpDisputePage } from "./components/CorpDisputes/CorpDisputePage";
import { FeatureSwitchesForm } from "./toolympus/featureswitch";
import { features } from "./features";
import { PowerDocsPlace } from "./components/PowerEditor";
import { SettingsPage } from "./components/SettingsPage";
import { DocumentationRoutes } from "./toolympus/components/Documentation";
import { PartiesPollsPage, PartiesPollsPrepPage } from "./components/PartiesPolls";
import { CMSEditor } from "./components/CMS";
import { NumberingPages } from "./components/Common/NumberingPages";
import { TimetrackingRateTablesConfiguration } from "./components/Timetracking";
import { QueryChunksConfigurationPage } from "./toolympus/components/Dataschema";
import { ConfRoomsConfigurationPage, EventsCalendarPage } from "./components/Organization/Calendar";
import { TasksHome } from "./components/Organization/Tasks";

const routes: (MenuRoute & { noContentBg?: boolean })[] = [
    {
      path: "/taskshome",
      title: "Главная",
      hidden: true,
      icon: <Home />,
      component: TasksHome,
      noContentBg: true,
    },


    {
        path: "/home",
        title: "Главная",
        icon: <Home />,
        component: () => <PagePage slug="home" />,
    },
    {
        path: "/help",
        title: "Справка",
        icon: <HelpOutline />,
        component: () => <DocumentationRoutes apiPath="/api/documentation" rootSlug="help-root" defaultRootPageTitle="Документация" />,
    },
    {
        path: "/admin-help-old",
        title: "Справка",
        component: () => <PagePage slug="admin-help" />,
        hidden: true,
    },
    { path: '/case/:casenbr', component: CaseContainer, hidden: true },
    { path: '/case', title: 'Дела', icon: <DescriptionOutlined />, component: CaseListContainer,
        alsoActivateForPath: (path: string) => path.startsWith('/case/'),
        resolveHidden: isCasefulUser },
    { path: '/arbitrator/:id', component: ArbitratorContainer, hidden: true },
    { path: '/arbitrators', title: 'Арбитры', icon: <Person />, component: ArbitratorListContainer,
        alsoActivateForPath: (path: string) => path.startsWith('/arbitrator/'),
        resolveHidden: isCasefulUser },
    { path: '/practice/:id', component: PracticeFormContainer, hidden: true },
    { path: '/practice', title: 'Практика', icon: <BookOutlined/>, component: PracticeListContainer,
        alsoActivateForPath: (path: string) => path.startsWith('/practice/'),
        resolveHidden: isCasefulUser },

    { path: '/corp-disputes/:id', component: CorpDisputePage, hidden: true },
    { path: '/corp-disputes', title: 'Корп. споры', icon: <RoomServiceOutlined />, component: CorpDisputesListPage,
        alsoActivateForPath: (path: string) => path.startsWith('/corp-disputes/'),
        resolveHidden: isCasefulUser },
    
    { path: '/parties-polls/prep', title: 'Опросы сторон', component: PartiesPollsPrepPage, hidden: true },
    { path: '/parties-polls', title: 'Опросы сторон', icon: <PollOutlined />, component: PartiesPollsPage,
        alsoActivateForPath: (path: string) => path.startsWith('/parties-polls/'),
        resolveHidden: isCasefulUser },



    ...PresidiumRoutes,

    { path: '/dsqc', title: 'Схема данных', component: QueryChunksConfigurationPage, componentProps: { apiPath: "/api/dsqc" }, hidden: true },

    { path: '/dashboards', title: 'Дэшборды', icon: <DashboardIcon/>, component: EditDashboardsContainer, hidden: true },
    { path: '/dashboard', title: 'Аналитика', icon: <DashboardIcon/>, component: DashboardContainer,
        alsoActivateForPath: (path: string) => path.startsWith('/dashboards'),
        resolveHidden: isCasefulUser },

    
    { path: '/system/employee/:id', title: 'Сотрудник', icon: <People/>, component: EmployeeForm, hidden: true },
    
    {
        path: "/page/:slug",
        title: "",
        icon: <PostAdd />,
        component: PagePage,
        hidden: true,
    },


    {
        path: "/features",
        title: "Features",
        hidden: true,
        component: () => <FeatureSwitchesForm featureManager={features} />,
    },


    {
        path: "/numbering",
        title: "Исх. Номера",
        icon: <Numbers />,
        alsoActivateForPath: p => p.startsWith("/numbering/"),
        component: NumberingPages,
    },


    {
        path: "/org/calendar",
        title: "Календарь",
        icon: <CalendarMonthOutlined />,
        alsoActivateForPath: p => p.startsWith("/org"),
        component: EventsCalendarPage,
    },
    {
        path: "/org/conf-rooms",
        title: "Переговорки",
        hidden: true,
        component: ConfRoomsConfigurationPage,
    },


    { 
        path: '/system',
        title: "Конфигурация",
        icon: <Settings />,
        component: () => (
            <SystemSubmenu
                dictionaries={{ languages: ["en"]}}
                users={{ allowCreateUsers: true, allowInvites: false, allowPaswordReset: true, twoFactorAuthEnabled: true }}
                apiTokens={{ apiPath: "/api/apitoken" }}
                queryConsole={{ apiPath: "/api/query-console" }}
                pages={[{ apiPath: "/api/page", label: "Страницы", key: "pages" }]}
                emails={{ apiPath: "/emails" }}
                eventLog={{ apiPath: "/api/event-log" }}
                notificationTasks={{ apiPath: "/api/notifications", emailsApiPath: "/api/emails", emailsPagePath: "/system/emails" }}
                robud={{}}
                schemas={{}}
                cms={{ apiPath: "/api/cms", EditorComponent: CMSEditor }}
                checkSectionAvailable={{
                    'users': u => u?.roles.includes("manager"),
                    'query-console': u => u?.roles.includes("manager"),
                    'event-log': u => u?.roles.includes("manager"),
                }}
                extraItems={[
                    {
                        key: "snaphots",
                        label: "Снэпшоты",
                        icon:  <ArchiveOutlined />,
                        items: [
                            { component: () => <SnapshotsContainer apiPath="/api/snapshots" />, key: "snapshots", label: "Снэпшоты" },
                    ]},
                    {
                        key: "settings",
                        label: "Настройки",
                        icon:  <Settings />,
                        items: [
                            { component: SettingsPage, key: "settings", label: "Настройки" },
                    ]},
                    {
                      key: "employee",
                      label: "Сотрудники",
                      icon:  <People />,
                      items: [
                          { component: EmployeeList, key: "employee", label: "Сотрудники" },
                    ]},
                    {
                      key: 'importexport',
                      label: 'Импорт / Экспорт',
                      icon: <ImportExport />,
                      items: [
                        { component: ImportExportContainer, key: "importexport", label: "Импорт / Экспорт" },
                    ]},
                    {
                      key: 'documents',
                      label: 'Шаблоны документов',
                      icon: <TextFieldsOutlined />,
                      items: [
                        { component: PowerDocsPlace, key: "documents", label: "Щаблоны документов" },
                    ]},
                    {
                      key: 'timerates',
                      label: 'Почасовые ставки',
                      icon: <TableViewOutlined />,
                      items: [
                        { component: TimetrackingRateTablesConfiguration, key: "timerates", label: "Почасовые ставки" },
                    ]},
                    
                ]}
                />),
        alsoActivateForPath: (path: string) => path.startsWith('/system/'),
        resolveHidden: isAdminManager,
    },
    
];

export default routes;
