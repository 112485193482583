import { FieldType, Schema } from "../../../toolympus/hooks/useSchema";

export interface PMTask {
  _id: string;
  project_id?: string;
  title: string;
  tags?: string[];
  assignees?: string[];
  due_date?: string;
  entry_order?: number;
  
  resolved_at?: string;
  resolved_by?: string;
  created_at?: string;
  created_by?: string;

  content?: any;
}


export const TaskSchema: Schema = {
  title: { label: "Название" },
  due_date: { label: "Срок", type: FieldType.date },
  assignees: { label: "Исполнители", type: FieldType.dictionarySelectMulti, dictionary: "Users" },
  // title: { label: "Название" },
}