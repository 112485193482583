import React from 'react';
import styled from '@emotion/styled';
import { Buttons, FormGrid, OccupyFreeSpace } from '../../../toolympus/components/primitives';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { EditTaskDialog } from './EditTaskDialog';
import { useTasksHome } from './useTasksHome';
import { TaskListSmall } from './TaskListSmall';

const HomeWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  padding: 20px;
`;

interface Props {
  
}

export const TasksHome = (props: Props) => {
  const data = useTasksHome();

  return (
    <HomeWrapper>
      <Buttons>
        <OccupyFreeSpace />

        <Button startIcon={<Add />} onClick={() => data.newTask.startEditing()}>создать задачу</Button>
      </Buttons>

      <FormGrid columns="1fr 1fr" forceEvenColumns>
        <TaskListSmall
          data={data.myTasks}
          title="Мои задачи"
          startEditing={t => data.editTask.startEditing({ ...t })}
          />

        <TaskListSmall
          data={data.byMeTasks}
          title="Задачи поставленные мной"
          startEditing={t => data.editTask.startEditing({ ...t })}
          />
      </FormGrid>
      
      <EditTaskDialog
        data={data.newTask}
        />

      <EditTaskDialog
        data={data.editTask}
        />
    </HomeWrapper>
  );
}
