import React from 'react';
import { EditItemProps } from '../../../toolympus/api/useNewItem';
import { PMTask, TaskSchema } from './types';
import { Dialog, FormGrid } from '../../../toolympus/components/primitives';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import isHotkey from 'is-hotkey';

interface Props {
  data: Omit<EditItemProps<Partial<PMTask>>, "startEditing" | "setItem">;
}

export const EditTaskDialog = (props: Props) => {
  const { data } = props;

  return (
    <Dialog
      noFullscreen
      dialogTitle="Задача"
      maxWidth="sm"
      onKeyUp={e => {
        if(isHotkey("mod+enter", e)) {
          data.save();
        }
      }}
      {...data.dialog}
      actions={<>
        <Button size="small" onClick={() => data.cancel()}><FormattedMessage id="common.cancel" /></Button>
        <Button size="small" color="primary" onClick={() => data.save()}><FormattedMessage id="common.save" /></Button>
      </>}>
      {!!data.item &&
        <FormGrid columns="1fr" noMargin>
          <FormControlsForFields
            fields={[
              ["title", { controlProps: { autoFocus: true }}],
            ]}
            data={data.item}
            onChange={(o,c) => data.update(c)}
            schema={TaskSchema}
            />
          
          <FormGrid columns="2fr 1fr" noMargin>
            <FormControlsForFields
              fields={[
                ["assignees", { autoComplete: true }],
                ["due_date"],
              ]}
              data={data.item}
              onChange={(o,c) => data.update(c)}
              schema={TaskSchema}
              />
          </FormGrid>
        </FormGrid>}
    </Dialog>
  );
}
