import { apiFetch } from "../../../toolympus/api/core";
import { useEditItem2 } from "../../../toolympus/api/useNewItem"
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { useUser } from "../../../toolympus/userContext/UserContext";
import { PMTask } from "./types"

const TasksApiPath = "/api/organization/pm/task";

export const useTasksHome = () => {
  const { user }= useUser();
  const myTasks = useLoadedData<PMTask[]>(`${TasksApiPath}?assignees=${user?._id}`, []);
  const byMeTasks = useLoadedData<PMTask[]>(`${TasksApiPath}?author=${user?._id}`, []);

  const newTask = useEditItem2<Partial<PMTask>>({
    save: (t: Partial<PMTask>) => {
      const tReal = { ...t };
      if(!tReal?.assignees?.length && user?._id) {
        tReal.assignees = [user._id];
      }
      return apiFetch<PMTask>(TasksApiPath, "post", tReal)
        .then(x => {
          myTasks.reload();
          byMeTasks.reload();
          return x;
        });
    },
  });

  const editTask = useEditItem2<PMTask>({
    save: (t: PMTask, changes: Partial<PMTask>) => {
      const changesReal = { ...changes };
      if(!!changesReal?.assignees && !changesReal?.assignees?.length && user?._id) {
        changesReal.assignees = [user._id];
      }
      return apiFetch<PMTask>(`${TasksApiPath}/${t._id}`, "put", changesReal)
        .then(x => {
          myTasks.reload();
          byMeTasks.reload();
          return x;
        });
    },
  })



  return {
    myTasks,
    byMeTasks,
    
    newTask: {
      ...newTask,
      startEditing: () => {
        newTask.startEditing({ title: "" });
      },
    },
    editTask,
  }
}
